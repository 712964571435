import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

// import Anuncio1 from "../images/anuncios/2024/febrero/1.jpeg";
// import Anuncio4 from "../images/anuncios/2024/febrero/4.jpeg";
// import MujeresHombres from "../images/anuncios/2024/febrero/5.jpeg";

import AnuncioJunio1 from "../images/anuncios/2024/junio/anuncio1.jpeg";
import AnuncioJunio2 from "../images/anuncios/2024/junio/anuncio2.jpeg";
import AnuncioJunio3 from "../images/anuncios/2024/junio/anuncio3.jpeg";
import AnuncioJunio4 from "../images/anuncios/2024/junio/anuncio4.jpeg";
import TempAnuncio2 from "../images/anuncios/2024/febrero/2.jpeg";
import TempAnuncio3 from "../images/anuncios/2024/febrero/3.jpeg";

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// markup
const AnunciosPage = () => {
  const [imageSelected, setImageSelected] = React.useState(AnuncioJunio1);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "120px",
    slidesToShow: 3,
    speed: 500,
    focusOnSelect: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  const selectImage = (image) => {
    const element = document.getElementById("divFirst");
    const y = element?.getBoundingClientRect().top + window.scrollY - 100;
    window.scroll({
      top: y,
      behavior: 'smooth'
    });
    setImageSelected(image)
  }


  return (
    <>
      <Header></Header>

      <div className="Index-page-content">
        <h1 style={{ textAlign: "center" }}>Anuncios</h1>
      </div>

      <Slider {...settings} className="overflow-hidden">
        <img onClick={() => selectImage(AnuncioJunio1)} className="pointer" src={AnuncioJunio1} />
        <img onClick={() => selectImage(AnuncioJunio2)} className="pointer" src={AnuncioJunio2} />
        <img onClick={() => selectImage(AnuncioJunio3)} className="pointer" src={AnuncioJunio3} />
        <img onClick={() => selectImage(AnuncioJunio4)} className="pointer" src={AnuncioJunio4} />
        <img onClick={() => selectImage(TempAnuncio2)} className="pointer" src={TempAnuncio2} />
        <img onClick={() => selectImage(TempAnuncio3)} className="pointer" src={TempAnuncio3} />
      </Slider>





      <div className="row sqs-row flex-parent" >
        <div className="col sqs-col-2 span-2">
          <div
            className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
            data-block-type="21"
          >
            <div className="sqs-block-content">&nbsp;</div>
          </div>
        </div>
        <div className="col sqs-col-8 span-8">
          <div
            className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
            data-block-type="21"
          >
            <img id="divFirst" width="100%" src={imageSelected}></img>
          </div>
        </div>

        <div className="col sqs-col-2 span-2">
          <div
            className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
            data-block-type="21"
          >
            <div className="sqs-block-content">&nbsp;</div>
          </div>
        </div>
      </div>




      <div className="row sqs-row" style={{ marginTop: "50px" }}>
        <div className="col sqs-col-2 span-2">
          <div
            className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
            data-block-type="21"
          >
            <div className="sqs-block-content">&nbsp;</div>
          </div>
        </div>

        <div className="col sqs-col-2 span-2">
          <div
            className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
            data-block-type="21"
            id="block-yui_3_17_2_2_1453931017574_19257"
          >
            <div className="sqs-block-content">&nbsp;</div>
          </div>
        </div>
      </div>


      <Footer></Footer>
    </>
  );
};

export default AnunciosPage;
